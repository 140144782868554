import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getCosts: createCancelTokenHandler('getCosts'),
  getCostActivitiesLog: createCancelTokenHandler('getCostActivitiesLog')
}
function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getCosts (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/cost${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCosts'].handleRequestCancellation().token
  })
}
export function getCost (id) {

  return axios({
    url: `v1/admin/treasury/cost/${id}`,
    method: 'get'
  })
}

export function printCosts ( filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/cost?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getCostTypes () {

  return axios({
    url: 'v1/admin/treasury/cost-types',
    method: 'get'
  })
}

export function getCostActivitiesLog (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/cost/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getCostActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertCost (payment) {

  return axios({
    url: 'v1/admin/treasury/cost',
    data: payment,
    method: 'post'
  })
}
export function deleteCost (id) {
  return axios({
    url: `v1/admin/treasury/cost/${id}`,
    method: 'delete'
  })
}

